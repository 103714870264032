import Accordion from "../Layout/Component/Accordion";
import { useState } from "react";
const dataSet = [
    {
        office : "PT. Ezeelink Indonesia",
        title : "Backend Developer",
        year : "September 2023 - Current",
        description: [
            "•	Generate API for Front End and Partner Endpoints",
            "•	Integrate and Support 4 Partner API",
            "•	Use C# as main Programming Language alongside with ASP .Net Core Framework",
            "•  Use Next JS for API Documentation with Nextra Framework",
            "•	Use SQL Server as Database and creating Stored Procedure Logic and Design Database and the relation",
            "•	Use WinSCP to Interact with 3rd party API",
            "•	Use Scrum as Software Development Lifecycle"
        ],
    },
    {
        office : "PT. Adicipta Inovasi Teknologi",
        title : "Fullstack Developer",
        year : "Feb 2022 - Feb 2023",
        description: [
            "•	Implements software feature for Clients needs",
            "•	Used VB.Net & C# as main programming language and SQL Server as Database",
            "•	Experienced in Waterfall Software Development Life Cycle",
            "•	Made stored procedure for several function required in development",
            "•	Tested software feature post development phase",
            "•	Created API with REST Architecture using C#",
            "•	Maintained new feature after deployment to production",
            "•	Actively contributed to a group project where we analyzed and solved user problems, problem-solving and analytical skills.",
        ],
    }
]

const WorkingExperiences = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleAccordionChange = (idx) => {
        setExpandedIndex(idx === expandedIndex ? null : idx);
    };

    return (
        <div>
            <h1 className="kanit-medium text-5xl text-center pb-8 text-slate-300">
                Working Experiences
            </h1>
            <div className="flex justify-center">
                <div className="w-[85%]">
                {dataSet.map((data, idx) => (
                    <Accordion
                        key={idx} // Don't forget to provide a unique key for each accordion
                        header={data.office}
                        idx={idx}
                        year={data.year}
                        title={data.title}
                        description={data.description}
                        expandedIndex={expandedIndex}
                        onAccordionChange={handleAccordionChange}
                    />
                ))}
                </div>
            </div>
        </div>
    );
}

export default WorkingExperiences;