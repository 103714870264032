import Card from "../Layout/Component/Card";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useEffect } from "react";
import gsap from "gsap";
import { HoverEffect } from "../Layout/Component/UI/card-hover-effect";
import { FaGolang } from "react-icons/fa6";
const dataSet = [
    {
    image: "openweather.jpg",
    stack: [
      {
        imageName: "https://api.iconify.design/carbon:logo-react.svg",
        tooltipName: "React JS",
      },
      {
        imageName: "https://api.iconify.design/ion:logo-javascript.svg",
        tooltipName: "Javascript",
      },
      {
        imageName: "https://api.iconify.design/mdi:tailwind.svg",
        tooltipName: "Tailwind",
      },
    ],
    title: "Weather App",
    desc: "Weather App using Weather API in React to show the specific country the temperature and location",
    hyperlink: "https://github.com/Vincent7564/Weather-App",
  },
  {
    image: "PeduliSekitar.png",
    stack: [
      {
        imageName: "https://api.iconify.design/simple-icons:laravel.svg",
        tooltipName: "Laravel",
      },
      {
        imageName: "https://api.iconify.design/mdi:language-php.svg",
        tooltipName: "PHP",
      },
      {
        imageName: "https://api.iconify.design/bi:bootstrap.svg",
        tooltipName: "Bootstrap",
      },
      {
        imageName: "https://api.iconify.design/bx:bxl-jquery.svg",
        tooltipName: "JQuery",
      },
    ],
    title: "Peduli Sekitar",
    desc: "Peduli sekitar is a school project which have same function as Kitabisa.com, this website is for helping people with donation",
    hyperlink: "https://github.com/Vincent7564/Weather-App",
  },
  {
    image: "Amazingbookstore.png",
    stack: [
      {
        imageName: "https://api.iconify.design/simple-icons:laravel.svg",
        tooltipName: "Laravel",
      },
      {
        imageName: "https://api.iconify.design/mdi:language-php.svg",
        tooltipName: "PHP",
      },
      {
        imageName: "https://api.iconify.design/bi:bootstrap.svg",
        tooltipName: "Bootstrap",
      },
      {
        imageName: "https://api.iconify.design/tabler:brand-mysql.svg",
        tooltipName: "MySQL",
      },
    ],
    title: "Amazing Bookstore",
    desc: "Amazing book store is a website for renting a book which have an auth for admin and customer built in laravel",
    hyperlink: "https://github.com/Vincent7564/Weather-App",
  },
  {
    image: "International_Pokémon_logo.svg.png",
    stack: [
      {
        imageName: "https://api.iconify.design/carbon:logo-react.svg",
        tooltipName: "React JS",
      },
      {
        imageName: "https://api.iconify.design/mdi:tailwind.svg",
        tooltipName: "Tailwind CSS",
      },
      {
        imageName: "https://api.iconify.design/mdi:webpack.svg",
        tooltipName: "Webpack",
      },
    ],
    title: "Poke-Wiki",
    desc: "A Simple Pokemon Finder Base on their name using Poke-Api.co with Tailwind and React, Collab with my friends",
    hyperlink: "https://github.com/laurensiavee/poke-wiki",
  },
  {
    image: "JavaScript-logo.png",
    stack: [
      {
        imageName: "https://api.iconify.design/ion:logo-javascript.svg",
        tooltipName: "Javascript",
      },
      {
        imageName: "https://api.iconify.design/tdesign:css3.svg",
        tooltipName: "CSS 3",
      },
      {
        imageName: "https://api.iconify.design/tabler:brand-html5.svg",
        tooltipName: "HTML 5",
      },
    ],
    title: "30 Days Javascript",
    desc: "A 30 days challenge of Javascript using Vanilla JS, CSS and few API using Fetch with ES6",
    hyperlink: "https://github.com/Vincent7564/learn-javascript",
  },
  {
    image: "V&V.png",
    stack: [ 
        {
          imageName: "https://api.iconify.design/carbon:logo-react.svg",
          tooltipName: "React",
        },
        {
          imageName: "https://api.iconify.design/mdi:tailwind.svg",
          tooltipName: "Tailwind",
        },
        {
          imageName: "https://api.iconify.design/simple-icons:express.svg",
          tooltipName: "Express JS",
        },
        {
          imageName: "https://api.iconify.design/teenyicons:mongodb-outline.svg",
          tooltipName: "Mongo DB",
        },
    ],
    title: "V&V E-Commerce Shop",
    desc: "An E-Commerce Website",
    hyperlink: "https://github.com/Vincent7564/E-Commerce-Frontend",
  },
  {
    image: "cheatsheet.png",
    stack: [
      {
        imageName: "https://api.iconify.design/akar-icons:vue-fill.svg",
        tooltipName: "Vue",
      },
      {
        imageName: "https://api.iconify.design/mdi:tailwind.svg",
        tooltipName: "Tailwind",
      },
      {
        imageName: "https://api.iconify.design/material-symbols:markdown.svg",
        tooltipName: "Markdown",
      },
    ],
    title: "Cheatsheet",
    desc: "Cheatsheet for certain framework,version controller and etc",
    hyperlink: "https://github.com/Vincent7564/cheatsheet",
  },
  {
    image: "spotify.png",
    stack: [
      {
        imageName: "https://api.iconify.design/akar-icons:nextjs-fill.svg",
        tooltipName: "Next JS",
      },
      {
        imageName: "https://api.iconify.design/mdi:tailwind.svg",
        tooltipName: "Tailwind",
      },
      {
        imageName: "https://api.iconify.design/akar-icons:typescript-fill.svg",
        tooltipName: "Typescript",
      },
    ],
    title: "Spotify API",
    desc: "Spotify Apps featuring integration with Spotify API",
    hyperlink: "https://github.com/Vincent7564/spotify-api",
  },
  {
    image: "todolist-next.png",
    stack: [
      {
        imageName: "https://api.iconify.design/akar-icons:nextjs-fill.svg",
        tooltipName: "Next JS",
      },
      {
        imageName: "https://api.iconify.design/mdi:tailwind.svg",
        tooltipName: "Tailwind",
      },
      {
        imageName: "https://api.iconify.design/akar-icons:typescript-fill.svg",
        tooltipName: "Typescript",
      },
      {
        imageName: "https://go.dev/blog/go-brand/Go-Logo/SVG/Go-Logo_Black.svg",
        tooltipName: "Go Lang",
      },
    ],
    title: "To Do List",
    desc: "To Do List Web Application with Go Backend",
    hyperlink: "https://github.com/Vincent7564/todolist_go",
  },
];
const Project = () => {

  return (
    <>
      <div className="">
        <h1 className="kanit-medium text-white text-5xl text-center pb-8">
          Projects
        </h1>
        <div className="flex justify-center">
          <div className="">
              <HoverEffect items={dataSet} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
